import { format } from 'date-fns'

const baseURL = `${process.env.REACT_APP_MY_API_URL}`
//const token = `${process.env.REACT_APP_MY_API_KEY}`

export const yrLevelMissing = async(student) => {
	try {
		const response = await fetch(
			baseURL + '?inYrLevel=' + student,
			{Accept: 'application.json'}
		)
		if(!response.ok) {
			throw new Error(`This is an HTTP error. The status is ${response.status}`)
		}
		return response.json()
	} catch {
		throw new Error('Could not update year level')
	}
}

export const updateYearLevel = async(student, yrLevel) => {
	try {
		const response = await fetch(
			baseURL + '?updateYrLevel=' + student + '&yrLevel=' + yrLevel,
			{Accept: 'application.json'}
		)
		if(!response.ok) {
			throw new Error(`This is an HTTP error. The status is ${response.status}`)
		}
		return 
	} catch {
		throw new Error('Could not update year level')
	}
}

export const sendFeedback = async(teacher, suggestion) => {
	try {
		const response = await fetch(
			baseURL + '?teacher=' + teacher + '&suggestion=' + suggestion,
			{Accept: 'application.json'}
		)
		if(!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}
		return response.json()
	} catch {
		throw new Error('Could not send the suggestion')
	}
}

export const getTickets = async (student, menu) => {
	try {
		const response = await fetch(
			baseURL + '?tickets=' + student + '&which=' + menu,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}
		return response.json()
	} catch {
		throw new Error('Could not get ticket list')
	}
}

export const deleteTicket = async (id, menu) => {
	try {
		await fetch(baseURL + '?delete=' + id + '&which=' + menu, {
			Accept: 'application.json',
		})
	} catch {
		throw new Error('Could not delete ticket list')
	}
}

export const getTicketCount = async (student, menu) => {
	try {
		const response = await fetch(
			baseURL + '?getCount=' + student + '&which=' + menu,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}
		return response.json()
	} catch {
		throw new Error('Could not get student count')
	}
}

export const getTicketColor = (count) => {
	if (count > 10) return 'red'
	if (count > 5) return 'orange'
	else return 'green'
}

export const getPeriod = async (time) => {
	const theCall =
		baseURL +
		'?period=' +
		format(new Date(time), "HH':'mm") +
		'&date=' +
		format(new Date(time), "yyyy'-'MM'-'dd")
	try {
		const response = await fetch(theCall, { Accept: 'application.json' })
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}
		return response.json()
	} catch {
		throw new Error('Could not get period')
	}
}

export const getMenuItems = async (menu) => {
	try {
		const response = await fetch(baseURL + '?menu=' + menu, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}
		return response.json()
	} catch {
		throw new Error('Could not get menu items')
	}
}

export const getTrackerList = async () => {
	try {
		const response = await fetch(baseURL + '?tracker=G', {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}
		return response.json()
	} catch {
		throw new Error('Could not get tickets')
	}
}

export const returnStudent = (student) => {
	fetch(baseURL + '?tracker=R&id=' + student, {
		Accept: 'application:json',
	}).catch((err) => {
		console.error('Student Register: ', err.message)
	})
}

export const isAdmin = async (teacher) => {
	try {
		const response = await fetch(baseURL + '?isAdmin=' + teacher, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}
		return response.json()
	} catch {
		throw new Error('Could not get Admin status')
	}
}

export const getSportCount = async (student) => {
	try {
		const response = await fetch(baseURL + '?sport=' + student, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get Sport status')
	}
}

export const getDramaCount = async (student) => {
	try {
		const response = await fetch(baseURL + '?drama=' + student, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get Drama status')
	}
}

export const getMyClasses = async (teacher) => {
	try {
		const response = await fetch(baseURL + '?myClasses=' + teacher, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get your classes')
	}
}

export const getStudentLeaderboard = async (which, week) => {
	try {
		const response = await fetch(
			baseURL + '?studentLeaderboard=' + which + '&week=' + week,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get student leaderboard')
	}
}

export const getStaffLeaderboard = async (which, week) => {
	try {
		const response = await fetch(
			baseURL + '?staffLeaderboard=' + which + '&week=' + week,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get teacher leaderboard')
	}
}

export const getUniform = async (student) => {
	try {
		const response = await fetch(baseURL + '?uni_exempt=' + student, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get uniform status')
	}
}

export const getHeadphones = async (student) => {
	try {
		const response = await fetch(baseURL + '?headphones=' + student, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get headphone status')
	}
}

export const getDLH = async (student) => {
	try {
		const response = await fetch(baseURL + '?dlh=' + student, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get Diverse-Learner Hub status')
	}
}

export const getGum = async (student) => {
	try {
		const response = await fetch(baseURL + '?gum=' + student, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get Diverse-Learner Hub status')
	}
}

export const cleanUp = async() => {
	await fetch(baseURL + '?cleanUp', {Accept: 'application.json'})
}

export const getWeeks = async () => {
	try {
		const response = await fetch(baseURL + '?weeks', {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get the list of weeks')
	}
}

export const getTeacherTicketCount = async (teacher, which) => {
	try {
		const response = await fetch(
			baseURL + '?teacherTicketCount=' + teacher + '&which=' + which,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get latest week')
	}
}

export const getTeacherTickets = async (teacher, which) => {
	try {
		const response = await fetch(
			baseURL + '?teacherTickets=' + teacher + '&which=' + which,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get latest week')
	}
}

export const addToFURoom = async (student, teacher, date, reason, notes) => {
	try {
		const response = await fetch(
			baseURL +
				'?fuRoom=A&student=' +
				student +
				'&date=' +
				date +
				'&teacher=' +
				teacher +
				'&reason=' +
				reason +
				'&notes=' +
				notes,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get latest week')
	}
}

export const upDateYrLevels = async(student, pc) => {
	try {
		const response = await fetch(
			baseURL +
				'?updateYrLevel=' + pc.match(/\d+/) +
				'&student=' + student,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get latest week')
	}
}

export const updateFURoom = async (id, completed, superNotes) => {
	try {
		const response = await fetch(
			baseURL +
				'?fuRoom=U&id=' +
				id +
				'&completed=' +
				completed +
				'&superNotes=' +
				superNotes,
			{ Accept: 'application.json' }
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get latest week')
	}
}

export const getFURoomByDate = async (date) => {
	try {
		const response = await fetch(baseURL + '?fuRoom=R&date=' + date, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get latest week')
	}
}

export const getFURoomItem = async (id) => {
	try {
		const response = await fetch(baseURL + '?fuRoom=I&id=' + id, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get latest week')
	}
}

export const getNextWeek = async () => {
	try {
		const response = await fetch(baseURL + '?nextWeek', {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get the Weeks')
	}
}

export const getFURoomStudents = async (date) => {
	try {
		const response = await fetch(baseURL + '?fuRoomStudents=' + date, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get latest week')
	}
}

export const getClassList = async(theClass) => {
	try {
		const response = await fetch(baseURL + '?getClassList=' + theClass, {
			Accept: 'application.json',
		})
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get the class list')
	}
}

export const getTermCount = async (term, student, menu) => {
	try {
		const response = await fetch(
			baseURL +
				'?getTermCount=' +
				term +
				'&student=' +
				student +
				'&type=' +
				menu,
			{
				Accept: 'application.json',
			}
		)
		if (!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get the class list')
	}
}

export const getThursdayList = async() => {
	try {
		const response = await fetch(
			baseURL +
			'?thursdayList',
			{
				Accept: 'application.json'
			}
		)

		if(!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get the Thursday List')
	}
}

export const getRegData = async(register) => {
	try {
		const response = await fetch(
			`${baseURL}?regList=${register}`,
			{
				Accept: 'application.json'
			}
		)

		if(!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get the Register List')
	}
}

export const getTerm = async() => {
	try {
		const response = await fetch(
			baseURL +
			'?getTerm',
			{
				Accept: 'application.json'
			}
		)

		if(!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get the Thursday List')
	}
}

export const getRollOverCount = async(student) => {
	try {
		const response = await fetch(
			`${baseURL}?getRollOverCount=${student}`,
			{
				Accept: 'application.json'
			}
		)

		if(!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get the Thursday List')
	}
}

export const isRollOver = async(ticket) => {
try {
		const response = await fetch(
			`${baseURL}?isRollOver=${ticket}`,
			{
				Accept: 'application.json'
			}
		)

		if(!response.ok) {
			throw new Error(`This is an HTTP error: The status is ${response.status}`)
		}

		return response.json()
	} catch {
		throw new Error('Could not get RollOver')
	}
}
